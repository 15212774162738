@import url("https://use.typekit.net/fug0qzw.css");

/* data font adjustments */
@font-face {
  font-family: "antarctican-mono";
  src: url("https://use.typekit.net/af/1c512f/00000000000000007735cdf3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/1c512f/00000000000000007735cdf3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/1c512f/00000000000000007735cdf3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  ascent-override: 100%;
  descent-override: 70%;
  /* line-gap-override: 0%; */
}

:root {
  --root-size: 56.25%; /* equal to 9px = 1em = 1rem */
  font-size: var(--root-size);

  /* spacing units */
  --spacing-scale-ratio: 2; /* 2:1 ratio | multiply to scale up (2 * --spacing-scale-ratio) | divide to scale down (n / --spacing-scale-ratio)*/
  --space-0: 0.5rem; /* equal to --root-size / --spacing-scale-ratio */
  --space-1: calc(var(--space-0) * var(--spacing-scale-ratio)); /* 9px */
  --space-2: calc(var(--space-1) * var(--spacing-scale-ratio)); /* 18px */
  --space-3: calc(var(--space-2) * var(--spacing-scale-ratio)); /* 36px */
  --space-4: calc(var(--space-3) * var(--spacing-scale-ratio)); /* 72px */
  --space-5: calc(var(--space-4) * var(--spacing-scale-ratio)); /* 144px */
  --space-6: calc(var(--space-5) * var(--spacing-scale-ratio)); /* 288px */
  --space-7: calc(var(--space-6) * var(--spacing-scale-ratio)); /* 576px */
  --space-8: calc(var(--space-7) * var(--spacing-scale-ratio)); /* 1152px */
  --space-9: calc(var(--space-8) * var(--spacing-scale-ratio)); /* 2304px */

  /*typography*/

  --default-font: futura-pt, sans-serif, Helvetica Neue, Helvetica, Arial,
    Sans-Serif;
  --paragraph-font: "pragmatica", helvetica, arial, sans-serif;
  /* font families */
  --font-family-default: var(--paragraph-font);
  --font-family-header: futura-pt, sans-serif, Helvetica Neue, Helvetica, Arial,
    Sans-Serif;
  --font-family-data: "antarctican-mono", sans-serif;
  --font-primary: var(--font-family-default);
  --font-family-label: var(--font-family-default);

  /* font scaling */
  --font-scale-ratio: calc(4 / 3); /* 4:3 perfect fourth ratio */

  /* scaling */
  --font-size-h6: calc(
    var(--space-1) * var(--font-scale-ratio)
  ); /* 12px | 1.3333rem */
  --font-size-h5: calc(
    var(--font-size-h6) * var(--font-scale-ratio)
  ); /* 16px  |  1.7778rem */
  --font-size-h4: calc(
    var(--font-size-h5) * var(--font-scale-ratio)
  ); /* 21.3333px 2.3704rem */
  --font-size-h3: calc(
    var(--font-size-h4) * var(--font-scale-ratio)
  ); /* 28.4444px | 3.1605rem */
  --font-size-h2: calc(
    var(--font-size-h3) * var(--font-scale-ratio)
  ); /* 37.9259px | 4.214rem */
  --font-size-h1: calc(
    var(--font-size-h2) * var(--font-scale-ratio)
  ); /* 50.5679px 5.6187rem */

  --font-size-small: var(--font-size-h6);
  --font-size-p: var(--font-size-h5);
  --font-size-label: var(--font-size-h6);
  --font-size-button: var(--font-size-default);

  /* set default font size*/
  --font-size-default: var(--font-size-p);

  /* lineheight || "letting" */
  --line-height-default: calc(1 * 5 / 3);
  --line-height-header: 1;
  --line-height-p: var(--line-height-default);
  --line-height-small: var(--line-height-default);
  --line-height-label: var(--font-size-h5);

  font-family: var(--font-family-default);
  line-height: var(--line-height-default);

  --hue: 120;
  --saturation: 100%;
  --lightness: 50%;
  --alpha: 1;
  --greenE: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 45%),
    var(--alpha)
  );
  --greenE-95: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 45%),
    0.95
  );
  --greenE-90: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 45%),
    0.9
  );
  --greenE-75: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 45%),
    0.75
  );
  --greenE-50: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 45%),
    0.5
  );
  --greenE-25: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 45%),
    0.25
  );
  --greenE-10: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 45%),
    0.1
  );
  --greenE-5: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 40%),
    0.05
  );
  --greenD: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 40%),
    var(--alpha)
  );
  --greenD-95: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 40%),
    0.95
  );
  --greenD-90: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 40%),
    0.9
  );
  --greenD-75: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 40%),
    0.75
  );
  --greenD-50: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 40%),
    0.5
  );
  --greenD-25: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 40%),
    0.25
  );
  --greenD-10: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 40%),
    0.1
  );
  --greenD-5: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 40%),
    0.05
  );
  --greenC: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 30%),
    var(--alpha)
  );
  --greenC-5: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 30%),
    0.05
  );
  --greenC-10: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 30%),
    0.1
  );
  --greenC-25: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 30%),
    0.25
  );
  --greenC-50: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 30%),
    0.5
  );
  --greenC-75: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 30%),
    0.75
  );
  --greenC-90: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 30%),
    0.9
  );
  --greenC-95: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 30%),
    0.95
  );

  --greenB: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 20%),
    var(--alpha)
  );
  --greenB-10: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 20%),
    0.1
  );
  --greenB-25: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 20%),
    0.25
  );
  --greenB-50: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 20%),
    0.5
  );
  --greenB-75: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 20%),
    0.75
  );
  --greenB-90: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 20%),
    0.9
  );
  --greenB-95: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 20%),
    0.95
  );
  --greenA: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) - 10%),
    var(--alpha)
  );
  --green0: hsla(var(--hue), var(--saturation), var(--lightness), var(--alpha));
  --green1: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 10%),
    var(--alpha)
  );
  --green2: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 20%),
    var(--alpha)
  );
  --green3: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 30%),
    var(--alpha)
  );

  --green4: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 40%),
    var(--alpha)
  );
  --green4-10: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 40%),
    0.1
  );
  --green4-25: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 40%),
    0.25
  );
  --green4-50: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 40%),
    0.5
  );
  --green4-75: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 40%),
    0.75
  );
  --green4-90: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 40%),
    0.9
  );
  --green4-95: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 40%),
    0.95
  );

  --green5: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 45%),
    var(--alpha)
  );
  --green5-10: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 45%),
    0.1
  );
  --green5-25: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 45%),
    0.25
  );
  --green5-50: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 45%),
    0.5
  );
  --green5-75: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 45%),
    0.75
  );
  --green5-90: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 45%),
    0.9
  );
  --green5-95: hsla(
    var(--hue),
    var(--saturation),
    calc(var(--lightness) + 45%),
    0.95
  );

  --purpleE: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 45%),
    var(--alpha)
  );

  --purpleD: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 40%),
    var(--alpha)
  );
  --purpleD-10: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 40%),
    0.1
  );
  --purpleD-25: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 40%),
    0.25
  );
  --purpleD-50: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 40%),
    0.5
  );
  --purpleD-75: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 40%),
    0.75
  );
  --purpleD-90: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 40%),
    0.9
  );
  --purpleD-95: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 40%),
    0.95
  );

  --purpleC: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 30%),
    var(--alpha)
  );
  --purpleC-10: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 30%),
    10%
  );
  --purpleC-25: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 30%),
    25%
  );
  --purpleC-50: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 30%),
    50%
  );
  --purpleC-75: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 30%),
    75%
  );
  --purpleC-90: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 30%),
    90%
  );

  --purpleB: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 20%),
    var(--alpha)
  );
  --purpleB-95: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 30%),
    0.95
  );
  --purpleB-90: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 30%),
    0.9
  );
  --purpleB-75: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 30%),
    0.75
  );
  --purpleA: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) - 10%),
    var(--alpha)
  );
  --purple0: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    var(--lightness),
    var(--alpha)
  );
  --purple1: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 10%),
    var(--alpha)
  );
  --purple2: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 20%),
    var(--alpha)
  );

  --purple3: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 30%),
    var(--alpha)
  );
  --purple3-10: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 30%),
    0.1
  );
  --purple3-25: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 30%),
    0.25
  );
  --purple3-50: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 30%),
    0.5
  );
  --purple3-75: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 30%),
    0.75
  );
  --purple3-90: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 30%),
    0.9
  );
  --purple3-95: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 30%),
    0.95
  );

  --purple4: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 40%),
    var(--alpha)
  );
  --purple4-10: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 40%),
    0.1
  );
  --purple4-25: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 40%),
    0.25
  );
  --purple4-50: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 40%),
    0.5
  );
  --purple4-75: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 40%),
    0.75
  );
  --purple4-90: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 40%),
    0.9
  );
  --purple4-95: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 40%),
    0.95
  );

  --purple5: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 45%),
    var(--alpha)
  );
  --purple5-10: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 45%),
    0.1
  );
  --purple5-25: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 45%),
    0.25
  );
  --purple5-50: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 45%),
    0.5
  );
  --purple5-75: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 45%),
    0.75
  );
  --purple5-90: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 45%),
    0.9
  );
  --purple5-95: hsla(
    calc(var(--hue) + 140),
    var(--saturation),
    calc(var(--lightness) + 45%),
    0.95
  );

  --blue0: hsla(
    calc(var(--hue) + 90),
    var(--saturation),
    var(--lightness),
    var(--alpha)
  );

  --blue1: hsla(
    calc(var(--hue) + 90),
    var(--saturation),
    calc(var(--lightness) + 10%),
    var(--alpha)
  );

  /*  semantic ui colors  */
  --danger: #ff6060;
  --warning: var(--yellow0);
  --success: var(--greenA);
  --info: var(--blue0);
  --primary: var(--green0);
  --secondary: var(--purpleB);
  --light: var(--greenD-10);
  --dark: var(--greenD-75);

  --background-color: var(--white);
  --font-color: var(--greenD-75);
}

html {
  all: unset;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

@media screen and (min-width: 576px) {
  html {
    overflow-x: unset;
  }
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

i,
.italic {
  font-family: var(--font-family-italic);
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: italic;
  font-variation-settings: "wdth" 100, "GRAD" 0;
}

data,
.data {
  font-family: var(--font-family-data);
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.text-bold {
  font-family: var(--font-family-default);
  font-weight: 500;
  font-style: normal;
}

.p-bold {
  font-family: var(--font-family-default);
  font-weight: 700;
  font-style: normal;
}

*,
[class*="auto-grid"] > * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

#root {
  width: 100vw;
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
  font-size: var(--font-size-default);
  overflow-x: hidden;
  color: var(--greenD-75);
  text-wrap: pretty;
}

/* scrollbar width */

::-webkit-scrollbar {
  --scrollbar-thickness: var(--space-1);
  width: var(--scrollbar-thickness);
  height: var(--space-1);
  background: var(--greenD-10);
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  border-radius: var(--space-0);
  background: var(--dark);
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: var(--greenD-10);
  border-radius: var(--space-0);
  cursor: grab;
}

/* Scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--greenD-25);
}

/* ------------- typography ------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--line-height-header);
  font-family: var(--font-family-default);
  text-wrap: balance;
  font-family: var(--font-family-header);
}

h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

h3 {
  font-size: var(--font-size-h3);
}

h4 {
  font-size: var(--font-size-h4);
}

h5 {
  font-size: var(--font-size-h5);
}

h6 {
  font-size: var(--font-size-h6);
}

a,
.link {
  cursor: pointer;
  color: var(--info);
}

a:hover,
.link:hover {
  filter: brightness(1.3);
}

p,
a {
  font-size: var(--font-size-p);
  line-height: var(--line-height-default);
  text-decoration: none;
}

small {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
}

small + small,
p + small,
h1 + small,
h2 + small,
h3 + small,
h4 + small,
h5 + small,
h6 + small {
  margin-block-start: var(--font-scale-ratio);
}

label {
  font-size: var(--font-size-label);
  display: block;
  line-height: var(--line-height-label);
  letter-spacing: 2px;
  text-transform: uppercase;
  color: var(--greenD-50);
  font-family: var(--font-family-default);
}

small {
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  display: block;
}

.font-family-data {
  font-family: var(--font-family-data);
}

strong {
  font-weight: 700;
}

/* ------------ UI elements -----------*/

button,
input[type="submit"] {
  background: var(--primary);
  padding: 1em 2em;
  border-radius: 2em;
  font-size: var(--font-size-default);
  color: var(--purpleB);
  font-weight: bold;
  font-family: var(--font-family-default);
  border: none;
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  gap: var(--space-1);
  position: relative;
  box-shadow: 0 6px 12px var(--greenD-10);
}

button.small,
input[type="submit"].small {
  padding: 0.5em 1em;
  border-radius: 0.5em;
  font-size: var(--font-size-h6);
}

button:hover,
input[type="submit"]:hover {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
  background: var(--royal-400);
  -webkit-transform: scale(1.025);
  -ms-transform: scale(1.025);
  transform: scale(1.025);
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}

button:active {
  transform: scale(1);
  filter: brightness(0.9);
}

button.secondary,
input[type="submit"].secondary {
  background: var(--secondary);
}

button.secondary:hover,
input[type="submit"].secondary:hover {
  filter: brightness(1.2);
}

button.dark,
input[type="submit"].dark,
button.hollow,
input[type="submit"].hollow {
  background: inherit;
}

button.dark:hover,
input[type="submit"].dark:hover,
button.hollow,
input[type="submit"].hollow {
  background: var(--dark);
}

button.hollow,
input[type="submit"].hollow {
  outline: 1px solid var(--primary);
  outline-offset: -1px;
}

button.hollow.secondary {
  outline: 1px solid var(--secondary);
}

button.success {
  background: var(--success);
}

button.info {
  background: var(--info);
}

input[type="checkbox"] {
  aspect-ratio: 1/1;
  width: 1em;
  height: 1em;
  margin: 0;
  padding: 0;
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
  /* background: var(--dark); */
  border: 2px solid var(--secondary);
}

hr {
  border: none;
  border-bottom: 1px solid var(--greenD-10);
  width: 100%;
}

button:disabled,
button[disabled] {
  opacity: 50%;
  pointer-events: none;
}

/* auto grid */
.auto-grid-5,
.auto-grid-small,
.auto-grid,
.auto-grid-medium,
.auto-grid-6,
.auto-grid-large,
.auto-grid-7 {
  display: -ms-grid;
  display: grid;
  grid-gap: var(--space-3);
}

.auto-grid-5,
.auto-grid-small {
  grid-template-columns: repeat(auto-fill, minmax(var(--space-5), 1fr));
  grid-gap: var(--space-2);
}

.auto-grid,
.auto-grid-6,
.auto-grid-medium {
  grid-template-columns: repeat(auto-fill, minmax(var(--space-6), 1fr));
}

.auto-grid-7,
.auto-grid-large {
  grid-template-columns: 1fr;
}

@media screen and (min-width: 786px) {
  .auto-grid-7,
  .auto-grid-large {
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--space-3);
  }
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.disabled {
  opacity: 50%;
  pointer-events: none;
}

.fa-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
  aspect-ratio: 1/1;
}

.blink {
  animation: blinker 0.66s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/*  */

.tooltip {
  position: relative;
}

.tooltip:hover {
  z-index: 1;
}

/* appears bottom center by default */
/* if used on buttons it will always try to appear center */
/* any "flex-centered" element will center the tooltip */
.tooltip::after {
  content: attr(data-tooltip);
  position: absolute;
  font-size: var(--font-size-small);
  background: var(--background-color);
  padding: var(--space-0) var(--space-1);
  border-radius: var(--space-0);
  visibility: hidden;
  color: var(--font-color);
  opacity: 0;
  pointer-events: none;
  top: calc(100% + var(--space-0));
  transition: opacity 0.3s ease-in-out;
  font-family: var(--font-family-data);
  text-align: left;
  z-index: 1;
  white-space: wrap;
  width: fit-content;
  min-width: 20ch;
  max-width: var(--space-6);
}

.tooltip:hover::after {
  visibility: visible;
  opacity: 1;
  filter: brightness(0.5) grayscale(0.75);
}

.tooltip.bottom::after {
  top: calc(100% + var(--space-0));
  right: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: unset;
}

.tooltip.bottom-left::after {
  top: calc(100% + var(--space-0));
  right: 0;
  left: unset;
  bottom: unset;
}

.tooltip.bottom-right::after {
  top: calc(100% + var(--space-0));
  right: unset;
  left: 0;
  bottom: unset;
}

.tooltip.top::after {
  top: unset;
  right: unset;
  left: unset;
  bottom: calc(100% + var(--space-0));
}

.tooltip.top-left::after {
  top: unset;
  right: 0;
  left: unset;
  bottom: calc(100% + var(--space-0));
}

.tooltip.top-right::after {
  top: unset;
  right: unset;
  left: 0;
  bottom: calc(100% + var(--space-0));
}

.tooltip.left::after {
  top: unset;
  left: unset;
  right: calc(100% + var(--space-0));
  bottom: unset;
  text-align: right;
}

.tooltip.right::after {
  top: unset;
  left: calc(100% + var(--space-0));
  right: unset;
  bottom: unset;
}

.tooltip.center::after {
  top: unset;
  left: unset;
  right: unset;
  bottom: unset;
  right: 50%;
  transform: translateX(50%);
}

.spin {
  animation: spin 1s linear infinite;
  transform-origin: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.system-message {
  font-family: var(--font-family-data);
  gap: var(--space-1);
  display: flex;
  background: transparent;
}

.system-message.warning {
  color: var(--warning);
}

.system-message.danger {
  color: var(--danger-400);
}

.system-message.info {
  color: var(--info-500);
}

.system-message.success {
  color: var(--success-400);
}

.system-message .icon {
  background: var(--warning);
  color: var(--background-color);
  aspect-ratio: 1/1;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--space-0);
}

.sentence-case {
  text-transform: math-auto;
}

select {
  appearance: none;
  -webkit-appearance: none;
  padding: 1em;
  border-radius: 1em;
  font-size: var(--font-size-default);
  background: inherit;
  outline: 1px solid var(--secondary);
  color: var(--white);
  border: none;
}

textarea {
  background: var(--dark);
  padding: 1em;
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--secondary);
  font-family: var(--font-family-default);
  font-size: var(--font-size-default);
  color: inherit;
}

section {
  max-width: var(--space-8);
  margin: 0 auto;
}

.hero-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  inset: 0;
  background-color: black;
  opacity: 0.5;
}

.hero-message {
  position: relative;
  z-index: 10;
  color: white;
  text-align: center;
}

@media (max-width: 1024px) {
  .hide-on-mobile {
    display: none;
  }
}
